.BillingExample {
  border: 2px solid #a8a8a8;
  padding: 1rem;
  user-select: none;
  overflow: hidden;
}

.Logo {
  width: 100%;
}

.CustomerName {
  border: 5px solid #444444;
  padding: 5px;
  text-align: center;
  font-weight: bold;
  font-size: 0.8rem;
  overflow: hidden;
}

.MerchantName {
  font-weight: 900;
  font-size: 1.4rem;
}

.MerchantInfo {
  font-weight: normal;
  font-size: 0.8rem;
}

.Bar {
  border: 1px solid #cdcdcd;
}

.Meajuda {
  background-color: #26427c;
  padding: 1rem;
  color: white;
  text-align: center;
  font-size: 0.6rem;
}

.Boleto {
  border: 1px solid #444444;
  padding: 1rem;
}

.PaymentMethod {
  color: #a8a8a8;
}