/* Misc. */
.inline-block { display: inline-block; }
.full-width { width: 100%; }

.cursor-default { cursor: default; }
.pointer { cursor: pointer; }
.cursor-edit { cursor: text; }

/* Text */
.noselect { user-select: none; }
.uppercase { text-transform: uppercase; }
.nowrap { white-space: nowrap; }

/* Lists. */
ul.horizontal {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ul.horizontal li {
  display: inline-block;
  vertical-align: top;
}

ul.horizontal.middle {
  display: table-row;
}

ul.horizontal.middle li {
  display: table-cell;
  vertical-align: middle;
}

ul.horizontal.relaxed {
  padding-top: 15px;
  padding-bottom: 15px;
}

ul.horizontal.relaxed li {
  padding-right: 10px;
  margin-bottom: 15px;
}

ul.horizontal.relaxed li:last-child {
  padding-right: 0;
}

ul.vertical {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ul.vertical li {
  display: block;
}

ul.vertical.relaxed {
  padding-left: 15px;
  padding-right: 15px;
}

ul.vertical.relaxed li {
  padding-bottom: 10px;
}

ul.vertical.relaxed li:last-child {
  padding-bottom: 0;
}

/* Alignment. */
.a-t { vertical-align: top; }
.a-m { vertical-align: middle; }
.a-b { vertical-align: bottom; }

.vertical-center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* Links. */
a.link:hover {
  text-decoration: none;
}

.unlink {
  text-decoration: none;
  border: none;
  color: inherit;
}

.unlink:hover {
  text-decoration: none;
}