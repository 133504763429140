.ListItem {
  font-size: 1.5rem;
  font-weight: 700;
  vertical-align: top;
  cursor: pointer;
  color: #14b1da;
  user-select: none;
  margin-right: 5px;
}

.ListItem:last-child {
  margin-right: 0;
}

.ListItem:hover {
  color: #53cbea;
}

.ListItem:active {
  color: #047694;
}

.Negative {
  color: #767676;
}

.Negative:hover {
  color: orangered;
}

.Negative:active {
  color: #e53e06;
}

.Positive {
  color: #767676;
}

.Positive:hover {
  color: #3cb371;
}

.Positive:active {
  color: #2ecc40;
}