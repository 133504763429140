.Icon {
  height: 48px;
  width: 48px;
  padding: 1px;
  margin-right: 10px;
  margin-bottom: 10px;
  transition: all 50ms ease-in;
  cursor: pointer;
  border-radius: 50%;
  filter: grayscale(80%);
}

.Icon:hover {
  transform: scale(1.35);
  box-shadow: 1px 1px 1px #a8a8a8;
  filter: grayscale(0);
}

.Icon:active {
  transform: scale(1.05);
  filter: grayscale(30%);
}

.IconSelected {
  filter: grayscale(0);
  transform: scale(1.25);
  border: 1px solid black;
}



/* Borrowed from CreateCustomerModal.module.css */
.ModalHeader {
  padding: 30px 10px 30px 20px;
  /*border-bottom: 1px solid #cdcdcd;*/
  background: linear-gradient(110deg, #4c64c5 1%, rgba(0, 0, 0, 0) 30%),
  radial-gradient(farthest-corner at 0% 0%, #4c92c5 70%, #4cacc5 70%);
  color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.ModalTitle {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
}

.ModalSubtitle {
  font-size: 14px;
  font-weight: normal;
  text-transform: none;
  color: #efefef;
}

.ModalBody {
  padding: 20px;
}

.ModalFooter {
  padding: 15px;
}

.FieldSep {
  padding-top: 16px;
  padding-bottom: 16px;
}

.FieldSep:first-child {
  padding-top: 16px;
}

.FieldHighlight {
  color: #a8a8a8;
}

.FieldHighlight:active,
.FieldHighlight:focus,
.FieldHighlight:hover {
  color: #282828;
}

.Nickname {
  background: linear-gradient(
      45deg,
      rgba(76, 172, 197, 1),
      rgba(38, 66, 124, 1)
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 800;
  font-size: 1.6rem;
  text-transform: uppercase;
}

.GoogleMapsContainer {
  border-bottom-left-radius: 0.8rem;
  border-bottom-right-radius: 0.8rem;
  overflow: hidden;
}

.ContactIcon {
  color: #cdcdcd;
  font-size: 16px;
  margin-left: 8px;
}

.Hoverable {
  cursor: pointer;
  transition: all 80ms ease-in;
}

.Hoverable:hover {
  color: #646464;
}

.InputText {
  /*-webkit-writing-mode: horizontal-tb !important;*/
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  text-transform: none;
  text-indent: 0;
  text-shadow: none;
  text-align: start;
  -webkit-appearance: textfield;
  -webkit-rtl-ordering: logical;
  cursor: text;
  margin: 0;

  font-family: inherit;
  box-sizing: border-box;
  color: #282828;

  display: block;
  width: 100%;
  transform: translateZ(0px);
  font-size: 16px;
  line-height: 38px;
  -webkit-font-smoothing: antialiased;
  padding: 0 0 8px;
  border-width: initial;
  border-style: none;
  border-bottom: 1px solid;
  outline: none;
  border-radius: 0;
  transition: box-shadow 0.2s ease 0s;
  background: none;
}

.InputText:not(:focus) {
  box-shadow: rgba(255, 255, 255, 0.3) 0 1px;
}

.InputText:focus {
  border-bottom: 1px solid transparent;
}

.SuggestionLink {
  color: #4c92c5;
  font-weight: bold;
  cursor: pointer;
}

.SuggestionLink:hover {
  color: #4cacc5;
}

.SuggestionLink:active {
  color: #26427c;
}

.SuggestionSelected {
  color: limegreen;
  font-weight: bold;
  cursor: pointer;
}

.ModalFinish {
  background-color: #4cacc5;
  padding: 20px;
  color: white;
  font-size: 2rem;
}

.ModalFinishFooter {
  background-color: #4cacc5;
  padding: 15px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}