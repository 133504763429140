.result {
  text-decoration: none;
  border: none;
  color: #767676;
}

.result:hover {
  text-decoration: none;
  color: #282828;
}

.ACTIVE {}

.ARCHIVED {
  text-decoration: line-through;
}

.DELETED {
  text-decoration: line-through;
}

.BLOCKED {}