.ModalHeader {
  padding: 30px 10px 30px 20px;
  background: linear-gradient(110deg, #4c64c5 1%, rgba(0, 0, 0, 0) 30%),
  radial-gradient(farthest-corner at 0% 0%, #4c92c5 70%, #4cacc5 70%);
  color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.ModalTitle {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
}

.ModalSubtitle {
  font-size: 14px;
  font-weight: normal;
  text-transform: none;
  color: #efefef;
}

.ModalBody {
  padding: 20px;
}

.ModalFooter {
  padding: 15px;
}

.ListItem {
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  user-select: none;
}

.ListItem:hover .Icon {
  transform: scale(1.3);
}

.ListItem:hover .Text {
  color: #282828;
}

.ListItem:active .Text {
  color: black;
}

.Icon {
  width: 36px;
  height: 36px;
}

.Text {
  margin-left: 20px;
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  text-transform: uppercase;
  font-size: 1.35rem;
  font-weight: 600;
  color: #767676;
}

.UsedCgroupList {
  display: inline-block;
}

.UsedCgroupItem {
  margin-right: .5rem;
}

.UsedCgroupItem:last-child {
  margin-right: 0;
}

.UsedCgroupIcon {
  height: 36px;
  width: 36px;
  cursor: pointer;
}