.ModalBody {
  padding: 0 14px 0 14px;
  /*background-color: #f2f2f2;*/
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
}

.FieldSep {
  padding-top: 30px;
}

.FieldLabel {
  font-size: 0.8rem;
  color: #8a8a8a;
}

.FieldView {
  font-weight: 600;
  font-size: 1.5rem;
  color: #555555;
}

.EditPlaceholder {
  color: #cdcdcd;
  cursor: pointer;
}

.MonthGroup {
  font-size: 1.4rem;
  font-weight: 600;
  color: #b8b8b8;
  cursor: default;
  text-align: center;
}

.NoteContainer {
  margin-top: 20px;
  margin-bottom: 20px;
  border: 1px solid #cdcdcd;
  border-radius: 13px;
  border-bottom-right-radius: 5px;
  background-color: rgb(236, 246, 255);
  box-shadow: 1px 1px 4px #cdcdcd;
  padding: 8px 10px 8px 15px;
  color: #444444;
  cursor: default;
}

.NoteText {
  font-size: 1.1rem;
}

.NoteContainer .NoteTimestamps {
  font-size: 0.7rem;
  color: rgb(236, 246, 255);
  margin-right: 15px;
}

.NoteContainer:hover .NoteTimestamps {
  color: #767676;
}

.NoteContainer .Delete {
  color: rgb(236, 246, 255);
  cursor: pointer;
  font-size: 16px;
}

.NoteContainer:hover .Delete {
  color: #a8a8a8;
}

.NoteContainer:hover .Delete:hover {
  color: #767676;
}

.NoteContainer:hover .Delete:active {
  color: #282828;
}

