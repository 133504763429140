/* Grey scale. */
.black { color: #000000; }
.piano-black { color: #282828; }
.dark { color: #444444; }
.darker-grey { color: #646464; }
.dark-grey { color: #767676; }
.medium-grey { color: #838383; }
.grey { color: #a8a8a8; }
.light-grey { color: #b9b9b9; }
.lighter-grey { color: #dbdbdb; }
.lightest-grey { color: #f0f0f0; }
.white { color: #FFFFFF; }

/* Visual identity. */
.vi-blue { color: #26427c; }
.vi-cyan { color: #4cacc5; }
.vi-purple { color: #8a5c91; }

.dark-vivid-purple { color: #7a00cc; }
.light-vivid-purple { color: #c03fff; }

/* Common colors. */
.indigo { color: indigo; }
.red { color: #e53e06; }
.blue { color: #4c64c5; }
