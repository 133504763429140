.ModalBody {
  padding: 14px;
}

.SuccessIcon {
  font-size: 64px;
  color: limegreen;
}

.AmountCurrency {
  font-size: 0.75rem;
  margin-right: .3rem;
  cursor: default;
}

.AmountCurrencySmall {
  font-size: 0.52rem;
}

.Amount {
  font-weight: 600;
  font-size: 1.34rem;
  cursor: default;
}

.AmountSmall {
  font-size: 0.94rem;
}

.AmountInt {
  font-weight: 600;
  font-size: 1.34rem;
  cursor: default;
}

.AmountIntSmall {
  font-size: 0.94rem;
}

.AmountCents {
  font-weight: 600;
  font-size: 0.85rem;
  vertical-align: super;
  margin-left: .14rem;
  cursor: default;
}

.AmountCentsSmall {
  font-size: 0.6rem;
}

.List {
  text-align: center;
}

.ListItem {
  font-size: 1.5rem;
  font-weight: 700;
  padding-top: 10px;
  vertical-align: top;
  cursor: pointer;
}

.ListItem a:hover {
  text-decoration: none;
}

.ListItemLink {
  color: #767676;
  transition: all 120ms ease-in;
  vertical-align: top;
}

.ListItemNegative:hover, .ListItemNegative:hover > span {
  color: #d0021b;
}

.ListItemLink:hover {
  color: #282828;
}

.Send {
  color: #a8a8a8;
  cursor: pointer;
}

.Send:hover {
  color: #646464;
}

.Send:active {
  color: #282828;
}