.Button {
  color: #F25F5C;
  cursor: pointer;
  transition: all 125ms ease-in;
}

.Button:hover {
  transform: scale(1.1);
}

.Button:active {
  color: #e53e06;
  transform: scale(0.95);
}

.Disabled {
  color: #cdcdcd;
}