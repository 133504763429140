.LogoPlaceholder {
  height: 96px;
  width: 96px;
}

.Logo {
  max-height: 300px;
}

.LogoContainer {
  text-align: center;
}

.Header {
  font-size: 1.5rem;
  font-weight: bold;
  color: #646464;
  text-align: center;
}