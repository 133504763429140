.Item {
  margin-top: 10px;
  padding: 15px;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
}

.Available {
  border: 1px solid transparent;
  transition: all 125ms ease-in;
}

.Available:hover {
  border: 1px solid #cdcdcd;
}

.Available:active {
  border: 1px solid #a8a8a8;
  background-color: #f2f2f2;
}

.Selected {
  cursor: default;
  background-color: #f6f6f6;
}

.Disabled {
  cursor: default;
}
