.Page {
  min-height: calc(100vh - 57px);
  width: 100vw;
}

.ContainerFluid {
  width: 100%;
}

.Container {
  width: 100%;
}

@media screen and (max-width: 480px) {
  .Container {
    padding-left: 2.5%;
    padding-right: 2.5%;
  }
}

@media screen and (min-width: 481px) and (max-width: 991px) {
  .Container {
    padding-left: 10%;
    padding-right: 10%;
  }
}

@media screen and (min-width: 992px) {
  .Container {
    padding-left: 16%;
    padding-right: 16%;
  }
}

.Controls {
  padding: 5px;
  height: 60px;
}

.FloatRight {
  float: right;
}

@media (min-width: 992px) {
  .Outer {
    position: fixed;
    border-radius: 50%;
    width: 240px;
    height: 240px;
    left: -120px;
    bottom: -140px;
    background: linear-gradient(0deg, #4cacc5, #4cacc5 7%, #26427c 51%, #8a5c91 94%, #8a5c91);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#8a5c91",endColorstr="#4cacc5",GradientType=1);
    transition: all 500ms ease-in;
  }

  .Outer:hover {
    background: linear-gradient(3deg, #4cacc5, #4cacc5 7%, #26427c 51%, #8a5c91 94%, #8a5c91);
  }

  .Inner {
    position: fixed;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    left: -40px;
    bottom: -60px;
    background-color: #fbfbfb;
  }
}