.Name {
  font-weight: 800;
  color: #767676;
  font-size: 1.35rem;
  text-transform: uppercase;
  text-align: left;
  cursor: default;
}

.Email {
  font-weight: 500;
  color: #444444;
  font-size: 1.1rem;
  text-align: left;
  cursor: default;
}

.ChangePassword {
  font-size: 0.9rem;
  color: #4c64c5;
  cursor: pointer;
}

.ChangePassword:hover {
  text-decoration: underline;
}
