.ModalHeader {
  padding: 30px 10px 30px 20px;
  border-bottom: 1px solid #cdcdcd;
  background: linear-gradient(110deg, #444444 40%, rgba(0, 0, 0, 0) 30%),
  radial-gradient(farthest-corner at 0% 0%, #555555 70%, #646464 70%);
  color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.ModalTitle {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
}

.ModalSubtitle {
  font-size: 14px;
  font-weight: normal;
  text-transform: none;
  color: #efefef;
}

.ModalBody {
  padding: 20px;
}

.ModalFooter {
  padding: 15px;
}

.Photo {
  width: 220px;
  height: 228px;
  margin-bottom: 30px;
  padding: 5px;
  border-radius: 50%;
}

.Name {
  font-weight: 800;
  color: #767676;
  font-size: 1.35rem;
  text-transform: uppercase;
}

.Email {
  font-weight: 500;
  color: #444444;
  font-size: 1.1rem;
}