.Link {
  cursor: pointer;
  user-select: none;
}

.Link:hover {
  background-color: #f2f2f2;
}

.Link:active {
  background-color: #e6e6e6;
}