.ModalBody {
  padding: 14px 14px 30px 14px;
}

.List {
  text-align: center;
}

.ListItem {
  font-size: 1.5rem;
  font-weight: 700;
  padding-top: 10px;
  vertical-align: top;
  cursor: pointer;
}

.ListItem a:hover {
  text-decoration: none;
}

.ListItemLink {
  color: #767676;
  transition: all 120ms ease-in;
  vertical-align: top;
}

.ListItemNegative:hover, .ListItemNegative:hover > span {
  color: #d0021b;
}

.ListItemLink:hover {
  color: #282828;
}

.Negative {
  color: #767676;
  cursor: pointer;
}

.Negative:hover {
  color: #e53e06;
}

.Negative:active {
  color: orangered;
}

.Icon {
  height: 48px;
  width: 48px;
  filter: grayscale(60%);
  transition: all 125ms ease-in;
  cursor: pointer;
  color: limegreen;
}

.Icon:hover {
  transform: scale(1.1);
  filter: grayscale(0);
}

.Icon:active {
  transform: scale(0.95);
}

.IconStatic {
  height: 64px;
  width: 64px;
}

.Splash {
  height: 166px;
  width: 166px;
  text-align: center;
}