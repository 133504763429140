.List {
  text-align: center;
}

.ListItem {
  font-size: 2rem;
  font-weight: 700;
  padding-top: 20px;
  vertical-align: top;
}

.ListItem:hover {
  text-decoration: none;
}

.ListItemLink {
  color: #767676;
  transition: all 120ms ease-in;
  vertical-align: top;
  cursor: pointer;
  text-decoration: none !important;
}

.ListItemLink:hover {
  color: #282828;
}
