.Link {
  text-decoration: none;
  color: #4cacc5;
}

.Link:hover {
  text-decoration: none;
}

.Item {
  height: 13rem;
  width: 8.6rem;
  border-radius: 0.4rem;
  cursor: pointer;
  transition: all 125ms ease-in;
  text-align: center;
}

.Item:hover {
  transform: scale(1.02);
}

.Item:active {
  transform: scale(0.97);
}

.PriceTag {
  padding-top: 2rem;
}

.ACTIVE, .PAUSED {
  background:
      radial-gradient(farthest-side at 5% 5%, rgba(38, 66, 124, 0.45) 78%, rgba(138, 92, 145, 0.35) 78%),
      linear-gradient(315deg, rgba(76, 172, 197, 0.95) 8%, rgba(38, 66, 124, 0.95) 85%);
}

.PAUSED {
  background:
      radial-gradient(farthest-side at 5% 5%, rgba(170, 170, 170, 0.45) 78%, rgba(180, 180, 180, 0.35) 78%),
      linear-gradient(315deg, rgba(190, 190, 190, 0.95) 8%, rgba(180, 180, 180, 0.95) 85%);
}

.StatusStripe {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.3);
  color: #f6f6f6;
  padding: .8rem;
  font-size: 0.9rem;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 1rem;
}

.MoonStripe {
  width: 100%;
  padding: 1rem;
  text-align: center;
}

.Moon {
  font-size: 3rem;
  color: #d3d3d3;
}

.ShowArchived {
  cursor: pointer;
  color: #a8a8a8;
  font-size: 0.9rem;
  font-weight: normal;
  float: right;
}

.ShowArchived:hover {
  color: #444444;
}
