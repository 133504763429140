.ModalBody {
  padding: 14px 14px 30px 14px;
}

.FieldSep {
  padding-top: 30px;
}

.FieldLabel {
  font-size: 0.8rem;
  color: #8a8a8a;
}

.FieldView {
  font-weight: 600;
  font-size: 1.5rem;
  color: #555555;
}

.EditPlaceholder {
  color: #cdcdcd;
  cursor: pointer;
}

.Amount {
  font-size: 2rem;
  font-weight: bold;
}

.DueDate {
  font-size: 1.5rem;
  font-weight: 500;
}

.List {
  text-align: center;
}

.ListItem {
  font-size: 1.5rem;
  font-weight: 700;
  padding-top: 10px;
  vertical-align: top;
  cursor: pointer;
}

.ListItem a:hover {
  text-decoration: none;
}

.ListItemLink {
  color: #767676;
  transition: all 120ms ease-in;
  vertical-align: top;
}

.ListItemNegative:hover, .ListItemNegative:hover > span {
  color: #d0021b;
}

.ListItemLink:hover {
  color: #282828;
}

.ActionButtonDisabled {
  cursor: default;
  color: #cdcdcd;
}

.ActionButton {
  cursor: pointer;
  transition: all 125ms ease-in;
  user-select: none;
}

.ActionButton:hover {
  transform: scale(1.3);
}

.ActionButton:active {
  transform: scale(0.9);
}

.Expand {
  font-size: 36px;
  color: #a8a8a8;
  cursor: pointer;
}
