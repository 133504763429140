.MeterProgress {
  appearance: none;
  width: 250px;
  height: 8px;
}

.MeterProgress::-webkit-progress-bar {
  background-color: #eee;
  border-radius: 3px;
}

.MeterProgress::-webkit-progress-value {
  border-radius: 2px;
  background-size: 35px 20px, 100% 100%, 100% 100%;
}

.Strength-0::-webkit-progress-value {
  background-color: #F25F5C;
}
.Strength-1::-webkit-progress-value {
  background-color: #dc9700;
}
.Strength-2::-webkit-progress-value {
  background-color: #FFE066;
}
.Strength-3::-webkit-progress-value {
  background-color: #247BA0;
}
.Strength-4::-webkit-progress-value {
  background-color: #2ecc40;
}