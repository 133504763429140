.MonthsNavigator {
  white-space: nowrap;
  user-select: none;
}

.MonthsNavigator:hover > .Name {
  color: #26427c;
}

.MonthsNavigator:hover > .Button {
  color: #4cacc5;
}

.MonthsNavigator:hover > .Button:active {
  color: #26427c;
}

.MonthsNavigator:hover > .Icon {
  color: #4cacc5;
}

.Name {
  display: inline-block;
  min-width: 80px;
  margin-right: 5px;
  margin-left: 5px;
  vertical-align: middle;
  text-align: center;
  color: #444444;
  transition: all 125ms ease-in;
  font-weight: bold;
}

.Icon {
  margin-left: 10px;
  margin-right: 10px;
  color: #ebebeb;
  transition: all 125ms ease-in;
}

.Button {
  color: #f0f0f0;
  cursor: pointer;
  margin-left: 5px;
  margin-right: 5px;
  transition: all 125ms ease-in;
}