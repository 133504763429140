.Currency {
  font-size: 1.1rem;
  font-weight: 700;
  margin-right: 0.4rem;
  color: #444444;
}

.AmountSettled {
  font-size: 2rem;
  font-weight: 800;
  color: #767676;
}

.DateCO {
  font-size: 1rem;
  color: #a8a8a8;
}
