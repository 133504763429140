.NavigateBack {
  color: #a8a8a8;
  cursor: pointer;
  transition: all 80ms ease-in;
  background-color: transparent;
  border: 0;
}

.NavigateBack:hover {
  color: #444444;
  transform: scale(1.01);
}

.NavigateBack:focus,
.NavigateBack:active {
  outline: none;
  transform: scale(0.97);
}