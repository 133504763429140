.ModalHeader {
  background-color: #f2f2f2;
  padding: 30px 20px 20px 20px;
  color: #efefef;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.ModalFooter {
  padding: 15px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.ContactIcon {
  color: #a8a8a8;
  font-size: 56px;
}

.ModalTitle {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: #676767;
}

.ModalSubtitle {
  font-size: 13px;
  font-weight: normal;
  text-transform: none;
  color: #a8a8a8;
}

.ProfileLink:hover > * {
  color: #646464;
}

.ArchivedTag {
  background-color: #d0021b;
  color: white;
  border-radius: 5px;
  font-size: 0.8rem;
  padding: 3px 9px 3px 9px;
  margin-left: 8px;
  text-decoration: none;
}

.ArchivedTag:hover {
  background-color: orangered;
  text-decoration: none;
  color: white;
}

.StretchedItem {
  width: 100%;
}

.TabContainer {
  padding-top: 10px;
  text-align: center;
}

.TabBtn {
  display: inline-block;
  vertical-align: middle;
}

@media screen and (max-width: 379px) {
  .TabBtn span {
    display: none;
  }
}

.ButtonLeftEdge {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.ButtonInBetween {
  border-radius: 5px !important;
}

.ButtonRightEdge {
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}

.InBetween {
  display: inline-block;
}

.ButtonSelected {
  background-color: #676767 !important;
  color: white !important;
  cursor: default !important;
}

.ButtonSelected:hover {
  transform: scale(1) !important;
}

.CreateReceivable {
  position: relative;
  border: 2px solid #f2f2f2;
  background: linear-gradient(110deg, #4c64c5 1%, #4cacc5);
  color: white;
  padding: 8px;
  width: 100%;
  height: 56px;
  border-radius: 999px;
  margin-left: -16px;
  margin-right: -14px;
  z-index: 3;
  font-size: 36px;
  transition: all 125ms ease-in;
}

.CreateReceivable:hover {
  transform: scale(1.2);
}

.CreateReceivable:active {
  transform: scale(1.1);
}

.CreateReceivableSelected {
  background: #676767;
  color: white;
}

.CreateReceivableSelected:hover {
  transform: scale(1);
  cursor: default;
}
