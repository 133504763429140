.ListItem {
  font-size: 1.5rem;
  font-weight: 700;
  padding-top: 10px;
  vertical-align: top;
  cursor: pointer;
}

.ListItem a:hover {
  text-decoration: none;
}

.ListItemLink {
  color: #767676;
  transition: all 120ms ease-in;
  vertical-align: top;
}

.ListItemNegative:hover, .ListItemNegative:hover > span {
  color: #d0021b;
}

.ListItemLink:hover {
  color: #282828;
}