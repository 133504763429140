/* See https://github.com/reactjs/react-modal/issues/392 */
.ModalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1290;
}

.ModalWindow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ModalFrame {
  height: 100vh;
  width: 380px;
}

.small {
  width: 380px;
}

.medium {
  width: 480px;
}

.large {
  width: 620px;
}

.ModalContainer {
  margin: 40px 10px 40px 10px;
  background-color: white;
  border-radius: 8px 8px 8px 8px;
  box-shadow: 1px 3px 10px 2px rgba(0, 0, 0, 0.2);
  outline: none;
}

.ModalClose {
  position: relative;
  float: right;
  padding-top: 5px;
  padding-right: 5px;
}

.NavigateBack {
  cursor: pointer;
  transition: all 80ms ease-in;
  background-color: transparent;
  border: 0;
}

.NavigateBack:hover {
  transform: scale(1.01);
}

.NavigateBack:focus,
.NavigateBack:active {
  outline: none;
  transform: scale(0.97);
}

.LightGrey {
  color: #efefef;
}

.LightGrey:hover {
  color: white;
}

.DarkGrey {
  color: #a8a8a8;
}

.DarkGrey:hover {
  color: #282828;
}
