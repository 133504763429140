.ModalBody {
  padding: 14px;
}

.FieldSep {
  padding-top: 30px;
}

.FieldLabel {
  font-size: 0.8rem;
  color: #8a8a8a;
}

.FieldView {
  font-weight: 600;
  font-size: 1.5rem;
  color: #555555;
}

.EditPlaceholder {
  color: #cdcdcd;
  cursor: pointer;
}

.AmountBilled {
  font-size: 2rem;
  font-weight: bold;
}

.AmountOne {
  font-size: 1.5rem;
  font-weight: 600;
  cursor: default;
}

.DueDate {
  font-size: 1.5rem;
  font-weight: 500;
}

.List {
  text-align: center;
}

.ListItem {
  font-size: 1.5rem;
  font-weight: 700;
  padding-top: 10px;
  vertical-align: top;
  cursor: pointer;
}

.ListItem a:hover {
  text-decoration: none;
}

.ListItemLink {
  color: #767676;
  transition: all 120ms ease-in;
  vertical-align: top;
  background: none;
  border: none;
  font-weight: bold;
  outline: none;
}

.ListItemNegative:hover,
.ListItemNegative:hover > span {
  color: #d0021b;
}

.ListItemLink:hover {
  color: #282828;
}

.Send {
  color: #a8a8a8;
  cursor: pointer;
}

.Send:hover {
  color: #646464;
}

.Send:active {
  color: #282828;
}
