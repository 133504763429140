.ButtonCobranca {
  float: right;
}

@media screen and (max-width: 600px) {
  .ButtonCobranca {
    visibility: hidden;
    display: none;
  }
}

.Row {
  padding: 8px 5px 8px 5px;
  font-size: 1.25rem;
  border-bottom: 1px solid #ededed;
  transition: all 80ms ease-in;
  color: white;
  user-select: none;
  -webkit-user-drag: none;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.Row:hover {
  background-color: rgba(0, 0, 0, 0.01);
  color: #767676;
}

.Row > * {
  -webkit-user-drag: none;
}

.Row:active {
  background-color: rgba(0, 0, 0, 0.04);
}

.Row:last-child {
  border-bottom: 0;
}

.Icon {
  margin-right: 12px;
  transition: all 80ms ease-in;
  -webkit-user-drag: element;
}

.Icon:hover {
  transform: scale(1.1);
}

.Icon:active {
  color: #282828;
}

.Icon > * {
  -webkit-user-drag: none;
}

.NameTitle {
  font-weight: 600;
  margin-right: 15px;
  color: #282828;
  vertical-align: middle;
}

.NameTitle.deleted {
  color: #8a5c91;
}

.NameSubtitle {
  color: #a8a8a8;
  vertical-align: middle;
  font-size: medium;
}

.Link {
  text-decoration: none;
  border: none;
  color: inherit;
  margin-right: auto;
  margin-left: 0;
}

.Link:hover {
  text-decoration: none;
}

.ARCHIVED {
  text-decoration: line-through;
}

.Cgroups {
  display: inline-block;
  list-style-type: none;
  padding: 0;
  margin: auto 1rem auto 1rem;
}

.Cgroups li {
  display: inline-block;
  vertical-align: top;
  margin: 0 0.25rem;
}

.Cgroups li:last-child {
  margin-right: 0;
}

.CgroupIcon {
  height: 20px;
  width: 20px;
  cursor: pointer;
  transition: all 125ms ease-in;
}

.CgroupIcon:hover {
  transform: scale(1.1);
}

.Row .Cgroups {
  visibility: hidden;
}

.Row:hover .Cgroups {
  visibility: visible;
}
