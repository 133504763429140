.Body {
  padding: 0 14px 30px 14px;
}

.Expand {
  font-size: 36px;
  color: #a8a8a8;
  cursor: pointer;
}

.Expand:hover {
  color: #646464;
}

.Expand:active {
  color: #282828;
}