.Active {
  color: #4cacc5;
  font-weight: 700;
}

.Inactive {
  color: #282828;
}

.InactiveIcon {
  color: #282828;
}
