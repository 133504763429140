.Seen {
  color: limegreen;
}

.NotSeen {
  color: #d2d2d2;
}

.StatusDeleted {
  color: #cdcdcd;
}

.StatusPending {
  color: #a8a8a8;
}

.StatusCreated {
  color: #4c64c5;
}

.StatusOpenpayment {
  color: #282828;
}

.StatusCancelling {
  color: #767676;
}

.StatusNopayment {
  color: #444444;
}

.StatusUnderpayment {
  color: #7a00cc;
}

.StatusFullpayment {
  color: #2ecc40;
}

.StatusOverpayment {
  color: #646464;
}

.StatusPaid {
  color: #2b0dad;
}

.StatusRefund {
  color: #646464;
}

.StatusChargeback {
  color: orangered;
}

.StatusExpired {
  color: #a8a8a8;
}

.StatusSuspended {
  color: #646464;
}

.StatusExpired {
  color: #646464;
}

.StatusDeleted {
  color: #646464;
}

.StatusUnknown {
  color: #a8a8a8;
}
