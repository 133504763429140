.FieldSep {
  padding-top: 20px;
}

.BackBtn {
  font-size: 36px;
  border-radius: 50%;
  cursor: pointer;
}

.BackBtn:hover {
  background-color: #f2f2f2;
}