/*
 * react-slick.
 */
.slick-list {
  overflow: visible !important;
}

.slick-dots li button:before {
  font-size: 12px !important;
}

.ui.popup {
  z-index: 9999;
}

/*
 * semantic-ui.
 */

/* Buttons. */
.ui.button {
  font-family: "Proxima Nova", "Segoe UI", "Helvetica Neue", "Helvetica",
    sans-serif;
  border-radius: 8px;
  text-transform: uppercase;
  transition: 125ms all ease-in;
  font-weight: 700;
}

.ui.button .large {
  padding-top: 20px;
  padding-bottom: 20px;
}

.ui.button:hover {
  transform: scale(1.0352);
  outline: 0;
}

.ui.button:active {
  transform: scale(1);
}

.ui.button.primary {
  background: -webkit-linear-gradient(110deg, #a60af3 40%, rgba(0, 0, 0, 0) 30%),
    -webkit-radial-gradient(farthest-corner at 0% 0%, #7a00cc 70%, #c03fff 70%);
  background: -o-linear-gradient(110deg, #a60af3 40%, rgba(0, 0, 0, 0) 30%),
    -o-radial-gradient(farthest-corner at 0% 0%, #7a00cc 70%, #c03fff 70%);
  background: -moz-linear-gradient(110deg, #a60af3 40%, rgba(0, 0, 0, 0) 30%),
    -moz-radial-gradient(farthest-corner at 0% 0%, #7a00cc 70%, #c03fff 70%);
  background: linear-gradient(110deg, #4c64c5 1%, rgba(0, 0, 0, 0) 30%),
    radial-gradient(farthest-corner at 0% 0%, #4c92c5 70%, #4cacc5 70%);
  color: white;
}

.ui.button.primary:active {
  background: linear-gradient(110deg, #4c64c5 1%, rgba(0, 0, 0, 0.4) 30%),
    radial-gradient(farthest-corner at 0% 0%, #4c92c5 70%, #4cacc5 70%);
  color: #ebebeb;
}

.ui.button.primary:disabled {
  background-color: #a8a8a8;
  color: white;
}

.ui.button.secondary {
  border: 1px solid #a8a8a8;
  background-color: transparent;
  color: #a8a8a8;
}

.ui.button.secondary:hover {
  border: 1px solid #646464;
  background-color: transparent;
  color: #646464;
}

.ui.button.secondary:active {
  border: 1px solid #a8a8a8;
  background-color: transparent;
  color: #a8a8a8;
}

.ui.button.secondary:focus {
  border: 1px solid #a8a8a8;
  background-color: transparent;
  color: #a8a8a8;
}

.ui.button.secondary:disabled {
  border: 0 solid #a8a8a8;
  background-color: #dbdbdb;
  color: #a8a8a8;
}

.ui.button.pill {
  padding-right: 3rem;
  padding-left: 3rem;
}

/* Input. */
.ui.input input,
.ui.form .field input,
.ui.form .field input:focus {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #a8a8a8;
}

.ui.input input:focus,
.ui.form .field input:focus {
  border-bottom: 1px solid black;
}

.ui.input input::placeholder,
.ui.form .field input::placeholder {
  color: #a8a8a8;
}

.ui.form .field input:focus {
}

/* Checkbox. */
.ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
  background: linear-gradient(110deg, steelblue 10%, #4cacc5 90%) !important;
}

/* Breadcrumb. */
.ui .breadcrumb {
  background-color: Transparent;
  padding: 0;
}

/* BankAccountCard. */
.ui.card,
.ui.cards > .card {
  border-radius: 0.8rem;
}

.ui.card > :first-child,
.ui.cards > .card > :first-child {
  border-radius: inherit !important;
}

/* Header. */
.ui.header .content {
  vertical-align: middle;
}

/* Dimmer. */
.dimmed.dimmable > .ui.visible.dimmer {
  background: rgba(38, 67, 124, 0.75);
}

/* ModalDoneRight fix. */
.dimmed.dimmable > .ui.animating.dimmer,
.dimmed.dimmable > .ui.visible.dimmer,
.ui.active.dimmer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ui.page.modals {
  z-index: 1300;
}

.ui.modal,
.ui.active.modal {
  margin: 0 auto !important;
  top: auto !important;
  left: auto !important;
  transform-origin: center !important;
  transition: all ease 0.5s;
  border-radius: 0;
  z-index: 1290 !important;
}

/* Day picker. */
.DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
  background-color: #646464 !important;
}

.DayPicker-wrapper {
  outline: none;
}

/* Day selector. */
.ui.table.dayselector {
  border: 0;
  margin: 0 auto;
  background-color: transparent;
}

.ui.table.dayselector td {
  text-align: center;
  border: 0;
  border-radius: 50%;
  font-size: 0.9rem;
}

.ui.table.dayselector td.squared {
  border-radius: 5px;
}

.ui.table.dayselector td:hover {
  background-color: #f2f2f2;
}

.ui.table.dayselector td.active {
  background-color: #646464 !important;
  color: white !important;
  font-weight: bold;
}

.ui.table.dayselector td:not(.active) {
  cursor: pointer;
}

@media screen and (max-width: 419px) {
  .ui.table.dayselector td {
    font-size: 0.85rem;
  }
}
