.ModalBody {
  padding: 20px;
}

.SuccessIcon {
  font-size: 64px;
  color: limegreen;
}

.ListItemLink {
  color: #767676;
  transition: all 120ms ease-in;
  vertical-align: top;
}

.ListItemNegative:hover, .ListItemNegative:hover > span {
  color: #d0021b;
}

.ListItemLink:hover {
  color: #282828;
}

.Link {
  text-decoration: none;
  border: none;
  color: inherit;
}

.Link:hover {
  text-decoration: none;
}