.ModalBody {
  padding: 0 14px 14px 14px;
}

.FieldSep {
  padding-top: 30px;
}

.FieldLabel {
  font-size: 0.8rem;
  color: #8a8a8a;
  overflow-x: hidden;
}

.FieldView {
  font-weight: 600;
  font-size: 1.5rem;
  color: #555555;
  overflow: hidden;
}

.EditPlaceholder {
  color: #cdcdcd;
  cursor: pointer;
}

.DeleteField {
  color: #a8a8a8;
  cursor: pointer;
}

.DeleteField:hover {
  color: #646464;
}

.DeleteField:active {
  color: #282828;
}
