.Available {
  position: relative;
  overflow: hidden;
  display: inline-block;
  cursor: pointer;
  border-radius: 999px;
}

.Available input[type="file"] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  cursor: pointer;
}

.Available button {
  border: 2px solid #4cacc5;
  color: #4cacc5;
  background-color: white;
  padding: 8px 20px;
  border-radius: 999px;
  font-size: 1.2rem;
  font-weight: bold;
  cursor: pointer;
  transition: all 125ms ease-in;
}

.Available:hover button {
  transform: scale(1.2);
}

.Available:active button {
  background-color: #4cacc5;
  color: white;
  transform: scale(1);
}

.Uploading {
  font-size: 1.2rem;
  font-weight: bold;
  color: #26427c;
  cursor: default;
  padding: 8px 20px;
}

.Uploaded {
  font-size: 1.2rem;
  font-weight: bold;
  color: #4cacc5;
  cursor: default;
  padding: 8px 20px;
}

.Error {
  font-size: 1.2rem;
  font-weight: bold;
  color: #e53e06;
  cursor: default;
  padding: 8px 20px;
}