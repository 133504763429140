.Row {
  padding: 8px 5px 8px 5px;
  font-size: 1.25rem;
  border-bottom: 1px solid #ededed;
  transition: all 80ms ease-in;
  cursor: pointer;
  user-select: none;
}

.Row:hover {
  background-color: rgba(0, 0, 0, 0.02);
}

.Row:active {
  background-color: #cdcdcd;
}

.Row:last-child {
  border-bottom: 0;
}

.Name {
  font-weight: 600;
  margin-right: 1.1rem;
  color: #282828;
  vertical-align: middle;
}

.Name.deleted {
  color: #8a5c91;
}

.Nickname {
  font-weight: 700;
  color: #bbbbbb;
  vertical-align: middle;
  font-size: 0.9rem;
}

.Link {
  text-decoration: none;
  border: none;
  color: inherit;
}

.Link:hover {
  text-decoration: none;
}

.Row {
  padding: 0.7rem;
}

.Row:not(:last-child) {
  border-bottom: 1px solid #f6f6f6;
}

.Row:hover {
  background-color: #fbfbfb;
}

.StatusDate {
  color: #a8a8a8;
}

.Description {
  padding-top: 0 !important;
  color: #a8a8a8;
  font-style: italic;
  font-size: 0.89rem;
  text-align: left;
}