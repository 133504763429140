.FieldContainer {
  border-bottom: 1px solid #cdcdcd;
  vertical-align: top;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.NoBorder {
  border: 0;
}

.Icon {
  color: #cdcdcd;
  font-size: 20px;
  margin: auto;
  line-height: 100%;
  transition: all 125ms ease-in;
}

.HoverableIcon:hover .Icon {
  color: #767676;
}

.Icon > * {
  vertical-align: top;
}

.RightBtnContainer {
  margin: auto;
  line-height: 100%;
}

.InvalidMessage {
  color: #d0021b;
  font-size: 22px;
  cursor: help;
  transition: all 125ms ease-in;
  margin-right: 8px;
}

.ClearBtn {
  color: #767676;
  font-size: 22px;
  cursor: pointer;
  transition: all 125ms ease-in;
}

.ClearBtn:hover {
  color: #282828;
}

.ClearBtn:active {
  transform: scale(0.9);
}

.TextContainer {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}

.Text {
  display: block;
  margin: 0;
  padding: 5px 0 5px 8px;
  background: none;
  border-width: initial;
  border-style: none;
  outline: none;
  border-radius: 0;
  width: 100%;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #767676;
  cursor: text;
  box-sizing: border-box;

  transform: translateZ(0px);
  transition: box-shadow 0.2s ease 0s;
}

.Text:focus {
  color: #282828;
}

.Text::placeholder {
  color: #cdcdcd;
}

.TextLeft {
  text-align: left;
}

.TextCenter {
  text-align: center;
}

.TextRight {
  text-align: right;
}

.FontMedium {
  font-size: 16px;
}

.FontLarge {
  font-size: 24px;
}

.Locked {
  cursor: default;
  color: #b2b2b2;
}

.Invalid {
  background-color: #efdede;
}
