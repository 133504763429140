.SummaryBody {
  background-color: #f2f2f2;
}

.ScoreGrade {
  font-size: 3.2rem;
  line-height: 4rem;
  text-transform: uppercase;
  cursor: default;
}

.ScoreSubtitle {
  font-size: 0.9rem;
  text-transform: uppercase;
  font-weight: 600;
  cursor: default;
}

.KPI {
  font-size: 1.25rem;
  font-weight: 600;
  text-align: right;
  cursor: default;
}
