.FilterTitle {
  font-weight: bold;
  font-size: 1.35rem;
  margin-right: 1rem;
  vertical-align: middle;
}

.ClickableButton {
  color: #a8a8a8;
  cursor: pointer;
  transition: all 125ms ease-in;
}

.ClickableButton:hover {
  color: #646464;
}

.ClickableButton:active {
  color: #282828;
}
