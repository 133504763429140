.IconPE {
  color: #282828;
}

.AmountPE {
  font-weight: 800;
  font-size: 1.45rem;
  color: #282828;
}

.DatePE {
  margin-top: 2px;
  font-weight: 600;
  color: #646464;
}

.IconCO {
  color: #e2e2e2;
}

.AmountCO {
  font-weight: 700;
  font-size: 1.35rem;
  color: #a8a8a8;
}

.DateCO {
  margin-top: 2px;
  color: #646464;
  font-weight: 600;
}

.IconPR {
  color: #767676;
}

.AmountPR {
  font-weight: 700;
  font-size: 1.25rem;
  color: #646464;
}

.DatePR {
  margin-top: 2px;
  font-weight: 600;
  color: #646464;
}

.ShowMore {
  cursor: pointer;
  color: #a8a8a8;
  transition: all 125ms ease-in;
}

.ShowMore:hover {
  color: #444444;
}
