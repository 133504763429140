.Handle {
  transition: all 125ms ease-in;
  user-select: none;
}

.Active {
  color: #30BA30;
  cursor: pointer;
}

.Active:hover {
  transform: scale(1.2);
}

.Active:active {
  transform: scale(0.95);
  color: #2C9C2C;
}

.Inactive {
  color: #cdcdcd;
}