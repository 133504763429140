body {
  margin: 0;
  padding: 0;
  font-family: proxima nova, "Helvetica Neue", "Helvetica", "Segoe UI",
    sans-serif;
  -webkit-font-smoothing: antialiased;
}

html {
  font-size: 16px !important;
}

* {
  font-family: proxima nova, "Helvetica Neue", "Helvetica", "Segoe UI",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none !important;
}
