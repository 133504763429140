.AmountCurrency {
  font-size: 0.75rem;
  margin-right: .3rem;
}

.AmountCurrencySmall {
  font-size: 0.52rem;
}

.Amount {
  font-weight: 600;
  font-size: 1.34rem;
}

.AmountSmall {
  font-size: 0.94rem;
}

.AmountInt {
  font-weight: 600;
  font-size: 1.34rem;
}

.AmountIntSmall {
  font-size: 0.94rem;
}

.AmountCents {
  font-weight: 600;
  font-size: 0.85rem;
  vertical-align: super;
  margin-left: .14rem;
}

.AmountCentsSmall {
  font-size: 0.6rem;
}